import React, {useEffect} from 'react';
import {withStyles, useTheme} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PictureGrid from '../../component/PictureGrid/PictureGrid';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import Hidden from '@material-ui/core/Hidden';
import galleryGenerator from '../../assets/galleryGenerator';

const styles = theme => ({
    root: {
        padding: `0 ${theme.spacing(2)}px`,
        marginBottom: theme.spacing(2)
    },
    text: {
        margin: '15px 0',
    },
    fab: {
        position: 'fixed',
        bottom: '5%',
        right: '5%'
    }
})

const DetailedGallery = (props) => {

    // Make sure the page starts at the top of the screen on component mount
    useEffect(() => {
        window.scrollTo(0,0);
    })
    const theme = useTheme();
    const SMALL_SCREEN = useMediaQuery(theme.breakpoints.down('sm'));

    
    // Pull out the match parameters from react-router-dom
    const { params } = props.match;
    const { gallery } = props.location.state;
    const { classes } = props;

    // selectImages decides which asset of images to use based on url parameter
    const selectImages = (params) => {


        switch (params.detailView) {
            case '2017Gallery':
                return <PictureGrid sourcePictures={galleryGenerator(2016-2017)} galleryMode />
                // return <PictureGrid sourcePictures={galleryGenerator(galleryPictures["2016-2017"], 2016-2017)} galleryMode />
            case '2018Gallery':
                return <PictureGrid sourcePictures={galleryGenerator(2018)} galleryMode />
                // return <PictureGrid sourcePictures={galleryGenerator(galleryPictures["2018"], 2018)} galleryMode />
            case '2019Gallery':
                return <PictureGrid sourcePictures={galleryGenerator(2019)} galleryMode />
                // return <PictureGrid sourcePictures={galleryGenerator(galleryPictures["2019"], 2019)} galleryMode />
            case '2020Gallery':
                return <PictureGrid sourcePictures={galleryGenerator(2020)} galleryMode />
                // return <PictureGrid sourcePictures={galleryGenerator(galleryPictures["2020"], 2020)} galleryMode />
            default:
                break;
        }
    }

    return (
        <div className={classes.root}>
            <Typography variant={SMALL_SCREEN ? 'h3' : 'h2' } align='center' className={classes.text} color='primary'>{gallery.title}</Typography>
            {selectImages(params)}
            <Hidden smUp>
                <Fab variant='extended' onClick={props.history.goBack} className={classes.fab} color='primary'>Back</Fab>
            </Hidden>
        </div>
    )
}

export default withStyles(styles)(DetailedGallery);