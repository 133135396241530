// Utilize webpack's "require.context" and create an object of {image name: import location} key-value pair
const picImporter = contextObj => {
    const images = {}
    contextObj.keys().map(img => ( 
        // contextObj is a function until runtime and returns and object. require.context is trippy!
        images[img.replace(/^.*\//, '')] = contextObj(img)
    ))
    return images
}

export default picImporter;