import picImporter from './picImporter';

// Until Photos are hosted, this is a local solution to images
const picImportPathObj = picImporter(require.context('./pictures/layoutPhotos', false, /\.jpg$/i));

const servicePictures = [
    {
        title: 'Garden Design',
        imgSrc: picImportPathObj['1845_GardenDesign.JPG'],
        description: 'Make your dreams a reality',
    },
    {
        title: 'Tree Trimming',
        imgSrc: picImportPathObj['0040_TreeTrimming.JPG'],
        description: 'Trim the trees, Clear your view, and Improve the health of your trees',
    },
    {
        title: 'Sod Installation',
        imgSrc: picImportPathObj['1793_SodInstall.jpg'],
        description: 'For immediate coverage',
    },
    {
        title: 'Power Washing',
        imgSrc: picImportPathObj['0801_PowerWash.JPG'],
        description: 'Dirt should only be on the ground',
    },
    {
        title: 'Flowerbed Maintenance',
        imgSrc: picImportPathObj['1619_FlowerbedMaint.JPG'],
        description: 'Give love to your flowers, upkeep is key',
    },
    {
        title: 'Lawn-Safe Treatments',
        imgSrc: picImportPathObj['1839_LawnSafeChem.JPG'],
        description: 'People and pet-friendly chemical treatment',
    },
]

export default servicePictures;