export default {
    "gardenDesign": [
        "Personalized consultation for garden improvement",
        "Design unique garden layouts",
    ],
    "sodInstall": [
        "Get a green new lawn fast",
        "Remove old grass, weeds, and weed seeds",
        "Perfect for patching holes in the lawn",
        "Provides immediate coverage",
    ],
    "powerWashing": [
        "Outside, 1-2 story window washing",
        "Optional screen removal and cleaning",
        "Spot-free finish",
        "Sidewalks, driveways, brick walls, statuaries, brick and stone pathways"
    ],
    "lawn-safe": [
        "Ant control",
        "Grub control",
        "Rabbit control",
        "Preserve health and safety of your family and guests",
    ],
    "flowerbed": [
        "Seasonal colors for year-round beauty",
        "Watering",
        "Deadheading and shub Trimming",
        "Mulching and weeding",
    ],
    "treeTrimming": [
        "Clear headways for walking",
        "Dead-branch removal",
        "Tree trimming by consultation",
        "Inquire about additional tree services",
    ]
}