import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ContactForm from '../../component/ContactForm/ContactForm';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: `${theme.spacing(1)}px`
    },
    contactInfo: {
        [theme.breakpoints.up('sm')]: {
            margin: `${theme.spacing(4)}px auto ${theme.spacing(3)}px`,
            width: `${theme.spacing(7)}rem`
        }
    },
    spacedOut: {
        [theme.breakpoints.up('sm')]:{
            marginTop: `${theme.spacing(4)}px`,
            marginBottom: `${theme.spacing(8)}px`
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: `${theme.spacing(2)}px`
        }
    },
    shifted: {
        [theme.breakpoints.up('sm')]: {
            // margin: `0px auto`,
            // width: theme.spacing(30)
            marginLeft: `${theme.spacing(5)}px`
        },
        [theme.breakpoints.down('sm')]:{
            marginLeft: `${theme.spacing(3)}px`
        }
    },
    bolded: {
        fontWeight: 'bold'
    }
}));

const Contact = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const SMALL_SCREEN = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <>
            <Typography className={classes.root} align='center' variant='h2' color='primary'>Contact</Typography>
            <Grid container className={classes.contactInfo}>
                <Grid item sm={6}>
                    <ContactForm />
                </Grid>
                <Grid item sm={6}>
                    <div className={classes.shifted}>
                    <Typography variant='subtitle1' paragraph={SMALL_SCREEN ? true : false} className={classes.spacedOut}>
                        Our mission is to be a Community-Based, small business focused on providing Decent Jobs, Quality Grenery, and Extraordinary Service
                    </Typography>
                    <Typography color='primary' className={classes.bolded}>Call Today</Typography>
                    <Typography color='inherit' variant='subtitle1' gutterBottom={SMALL_SCREEN ? true : false}>
                        Hours of Operation <br />
                    Monday-Friday 8AM to 5PM <br />
                    Office: (214) 693-1500
                </Typography>
                </div>
                </Grid>
            </Grid>
        </>
    )
}
export default Contact;