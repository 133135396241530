import React from 'react';
import { List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import StopRoundedIcon from '@material-ui/icons/StopRounded';

const DiscriptionList = props => {
    const {descriptions} = props;

    return (
        <div>
            <List aria-label="service descriptions" dense disablePadding>
                {descriptions.map((desc, index) => (
                        <ListItem key={index} >
                            <ListItemIcon>
                                <StopRoundedIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText >
                                {desc}
                            </ListItemText>
                        </ListItem>
                ))}
            </List>
        </div>
    )
}

export default DiscriptionList;