import axios from 'axios';

export const formState = {
    firstName: {
        valid: false,
        value: ''
    },
    lastName: {
        valid: false,
        value: ''
    },
    email: {
        valid: false,
        value: ''
    },
    phone: {
        valid: false,
        value: ''
    },
    message: {
        valid: false,
        value: ''
    },
    validForm: false,
    showError: false,
}

const updateState = (stateObj, inputName, inputVal, newVal) => ({
    ...stateObj,
    [inputName]: {
        ...stateObj[inputName],
        [inputVal]: newVal
    }
})

export const formReducer = (state, action) => {
    switch (action.type) {
        case 'TYPE':
            // Captures user typing into the state
            const { name, value } = action.eventTarget;
            return updateState(state, name, "value", value);

        case 'VALIDATE':
            // Lightly validates form on submit click
            let updatedState = { ...state };
            let isValid = false;

            for (const key in state) {
                // *Name and Message validation - Make sure they're not blank
                if (key.search('Name') !== -1 || key === 'message') {
                    const NAME_REGEX = /.+/
                    const nameStr = state[key].value.trim();
                    const isBlank = nameStr.match(NAME_REGEX);
                    (isBlank !== null) ? isValid = true : isValid = false;
                    updatedState = updateState(updatedState, key, "valid", isValid);
                } else {
                    switch (key) {
                        case "email":
                            // Email needs at least an '@' some characters and then '.' + something
                            const EMAIL_REGEX = /.+@.+\..+/
                            const emailStr = state[key].value.trim(),
                                emailMatch = emailStr.match(EMAIL_REGEX);
                            (emailMatch !== null) ? isValid = true : isValid = false;
                            updatedState = updateState(updatedState, key, "valid", isValid);
                            break;
                        case "phone":
                            if (state[key].value !== ''){
                                // Must be in ###-###-#### format
                                const NUM_REGEX = /[2-9][0-8]\d-\d{3}-\d{4}/g
                                const phoneStr = state[key].value.trim();
                                const phoneMatch = phoneStr.match(NUM_REGEX);
                                (phoneMatch !== null) ? isValid = true : isValid = false;
                                updatedState = updateState(updatedState, key, "valid", isValid);
                            } else {
                                updatedState = updateState(updatedState, key, "valid", true);
                            }
                            break;
                        default:
                            updatedState = updateState(updatedState, key, "valid", false);
                            break;
                    }
                }
            };
            // Check that all inputs are valid
            let formIsValid = true;
            for (const input in updatedState) {
                if (input !== "validForm" && input !== "loading" && input !== "showError") {
                    if (updatedState[input].valid !== true) {
                        formIsValid = false;
                        break;
                    };
                }
            }
            updatedState = {
                ...updatedState,
                validForm: formIsValid,
                showError: true
            }
            return updatedState;
        case 'RESET':
            let newState = { ...state };
            for (const key in state) {
                if (key !== "validForm" && key !== "showError") {
                    newState = updateState(newState, key, "value", '');
                    newState = updateState(newState, key, "valid", false);
                }
            }
            newState = {
                ...newState,
                validForm: false,
                showError: false
            }
            return newState;
        default:
            throw new Error();
    }
}

export const formTyper = (event, dispatch) => {
    dispatch({ type: 'TYPE', eventTarget: event.target });
};
export const formValidator = (event, dispatch) => {
    event.preventDefault();
    dispatch({ type: 'VALIDATE' });
};
export const formSubmitter = (state, dispatch, setLoading, setSnackShow) => {
    setLoading(true)
    if (state.validForm === true) {
        const backendForm = {};
        // Make object of only entered values
        for (const key in state) {
            backendForm[key] = state[key].value
        }
        axios.post('/email/sendMail', backendForm)
            .then(response => {
                setLoading(false);
                if (response.data.statusCode === 250) {
                    // console.log("Successful Submission", response.data);
                    dispatch({ type: 'RESET' });
                    setSnackShow(true);
                } else {
                    // console.log("Submission Failed", response.data)
                }
            })
            .catch(error => {
                // console.log("API call failed", error);
            })

    } else {
        setLoading(false);
        alert('From is Invalid');
    }
};