import React from 'react';
import {useTheme} from '@material-ui/styles';
import { Link } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';

// Thumbnail to take user to the gallery view

const GalleryThumbnail = (props) => {
    const theme = useTheme();
    const SMALL_SCREEN = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Card raised style={SMALL_SCREEN ? {margin: `${theme.spacing(2)}px `} : {margin: `${theme.spacing(4)}px`} }>
            <CardActionArea component={Link} to={{
                pathname: `/gallery/${props.gallery.route}`,
                state: { gallery: props.gallery }
            }}>
                <CardMedia image={props.gallery.imgSrc} title={props.gallery.title} style={{ height: 0, paddingTop: '56.25%' }} />
                <CardContent>
                    <Typography variant='h5' align='center'>
                        {props.gallery.title}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}

export default GalleryThumbnail;