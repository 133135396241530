import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Dialog from '@material-ui/core/Dialog';
import Slideshow from '../Slideshow/Slideshow';
import DialogActions from '@material-ui/core/DialogActions';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#272323'
    }
}))

// Modal for showing detailed slideshow  
const GalleryModal = (props) => {
    const classes = useStyles()
    const {isOpen, handleClose, fullScreen, clickedData, sourcePictures, title} = props

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            maxWidth={fullScreen ? 'sm' : 'xl'}
            fullScreen={fullScreen}
            PaperProps={{ classes: { root: classes.root } }}
        >
            <Slideshow
                pickToOpen={title}
                startSlide={clickedData}
                sourcePictures={sourcePictures} />
            <DialogActions disableSpacing>
                <Button onClick={handleClose} variant='contained' color='primary'>Close</Button>
            </DialogActions>
        </Dialog >
    )
}
export default withMobileDialog()(GalleryModal);