import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
// Component imports
import servicePictures from '../../assets/servicePictures';
import longDescriptions from './longDescriptions';
import DescriptionList from '../DiscriptionList/DiscriptionList';

// This component renders a series of cards to display each service offered

class ServiceCards extends Component {
    state = {
        services:[
            {
                name: 'Garden Design',
                shortDescription: 'Plan Your Ideal Garden',
                longDescription: <DescriptionList descriptions={longDescriptions["gardenDesign"]} />,
                imgSrc: '',
                imgTitle: '',
                expanded: false
            },
            {
                name: 'Sod Installation',
                shortDescription: 'The Foundation of a Great Garden',
                longDescription: <DescriptionList descriptions={longDescriptions["sodInstall"]} />,
                imgSrc: '',
                imgTitle: '',
                expanded: false
            },
            {
                name: 'Power Washing',
                shortDescription: 'Power and Window Washing',
                longDescription: <DescriptionList descriptions={longDescriptions["powerWashing"]} />,
                imgSrc: '',
                imgTitle: '',
                expanded: false
            },
            {
                name: 'Lawn-Safe Treatments',
                shortDescription: 'Keep Critters Away Safely',
                longDescription: <DescriptionList descriptions={longDescriptions["lawn-safe"]} />,
                imgSrc: '',
                imgTitle: '',
                expanded: false
            },
            {
                name: 'Flowerbed Maintenance',
                shortDescription: 'Vibrant Flowers for the Long Haul',
                longDescription: <DescriptionList descriptions={longDescriptions["flowerbed"]} />,
                imgSrc: '',
                imgTitle: '',
                expanded: false
            },
            {
                name: 'Tree Trimming',
                shortDescription: 'Give Your Trees a Shape Up',
                longDescription: <DescriptionList descriptions={longDescriptions["treeTrimming"]} />,
                imgSrc: '',
                imgTitle: '',
                expanded: false
            }
        ],
    }

    componentDidMount(){
        this.addImages();
    }
    
    // addImages populates each state's image using servicePictures Asset
    addImages = () => {
        let imgToState = [...this.state.services],
        imgToStateObj; 

        for (let i = 0; i < this.state.services.length; i++) {
            for (let serviceIndex = 0; serviceIndex < servicePictures.length; serviceIndex++){
                // If this state's name matches servicePictures's name, add to state
                if(this.state.services[i].name === servicePictures[serviceIndex].title){
                    imgToStateObj = {
                        ...imgToState[i],
                        imgSrc: servicePictures[serviceIndex].imgSrc,
                        imgTitle: servicePictures[serviceIndex].title
                    }
                    imgToState[i] = imgToStateObj
                }
            }
            this.setState({services: imgToState})
        }
    }

    // Displays/Hides additional informaton of clicked Service Card
    handleExpandClick = (e) => {
        const clickedName = e.currentTarget.dataset.name;
        for (let i = 0; i < this.state.services.length; i++) {
            if (this.state.services[i].name === clickedName) {
                let updatedState = [...this.state.services];
                const updatedExpanded = {
                    ...updatedState[i],
                    expanded: !updatedState[i].expanded
                };
                updatedState[i] = updatedExpanded;
                this.setState({services: updatedState})
            }
        };
    };

    render() {
        // Run through each state opject and display on the page
        const services = this.state.services.map((service, index) => (
            <Grid item xs={12} sm={6} key={index}>
                <Box mx={(window.innerWidth < 600 ) ? 2 : ''}>
                    <Card>
                        <CardHeader title={service.name} />
                        {(service.imgSrc !== '') ? <CardMedia image={service.imgSrc} title={service.imgTitle} style={{ height: 0, paddingTop: '56.25%' }} /> : null}
                        <CardContent>
                            <Typography align='center'>{service.shortDescription}</Typography>
                        </CardContent>
                        <CardActions disableSpacing>
                            <Button
                                size='small'
                                color='primary'
                                variant='contained'
                                onClick={this.handleExpandClick}
                                data-name={service.name}
                                aria-expanded={this.state.expanded}
                                aria-label={service.expanded ? 'Show Less' : 'Show More'}>
                                {service.expanded ? 'Less' : 'More'}
                            </Button>
                        </CardActions>
                        <Collapse in={this.state.services[index].expanded} timeout='auto' unmountOnExit>
                            <CardContent>
                                {service.longDescription}
                            </CardContent>
                        </Collapse>
                    </Card>
                </Box>
            </Grid>
        ))

        return (
            <Box px={(window.innerWidth < 600 ) ? 0 : 16} mt={4}>
                <Grid container spacing={(window.innerWidth < 600 ) ? 3 : 5}>
                    {services}
                </Grid>
            </Box>
        )
    }
}

export default ServiceCards;