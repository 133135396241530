import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import TransitionSlide from '@material-ui/core/Slide';


const styles = () => ({
    slide: {
        height: '100%',
        width: '100%',
        display: 'inline-block',
    },
    media: {
        height: 0,
        paddingTop: '60%'
    },
})

const Slide = props => {
    const { classes, imageobject } = props;

    return (
            <TransitionSlide in={imageobject.in} direction={imageobject.direction} mountOnEnter unmountOnExit className={classes.slide}>
                {/* <img src={imageobject.imgSrc} alt='dog' /> */}
                <Card className={classes.card}>
                    <CardMedia image={imageobject.imgSrc} className={classes.media} />
                </Card>
            </TransitionSlide>
    )
}

export default withStyles(styles)(Slide);