import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

// For Mobile Views

const styles = theme => ({
    sidePanel: {
        width: 200
    },
    list: {
        marginTop: 20
    }
})

const linkItems = ['home', 'services', 'gallery', 'about', 'contact'];

const sideNavBar = (props) => {
    const {classes} = props;

    const sideLinks = (
        <div className={classes.sidePanel}>
            <List component='nav' className={classes.list}>
                {linkItems.map((text, index) => (
                    <ListItem key={index} button component={Link} to={text==='home' ? '/' : '/' + text}>
                        <ListItemText align='center'>
                            {text.charAt(0).toUpperCase() + text.slice(1)}
                        </ListItemText>
                    </ListItem>
                ))}
            </List>
        </div>
    )
    // Div below handles the closing of the Sidebar
    return (
        <Fragment>
            <Drawer open={props.open} onClose={props.toggleDrawer} anchor='left'>
                <div role='button' onClick={props.toggleDrawer} tabIndex={0}>
                    {sideLinks}
                </div>
            </Drawer>
        </Fragment>
    );
}

export default withStyles(styles)(sideNavBar);