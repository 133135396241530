import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import PictureGrid from '../../component/PictureGrid/PictureGrid';
import servicePictures from '../../assets/servicePictures';
import homeSplash from '../../assets/pictures/layoutPhotos/1619_HomeSplash.JPG';

const styles = theme => ({
    root: {
        color: '#eceff1',

    },
    splashImage: {
        backgroundImage: `url(${homeSplash})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundColor: 'grey',
        height: '50vh'
    },
    content: {
        margin: theme.spacing(3),
    },
    colorSection: {
        backgroundColor: theme.palette.primary.main,
        padding: theme.spacing(2),
    },
    pushDown: {
        marginBottom: theme.spacing(2)
    }
})

class HomePage extends Component {

    componentDidMount(){
            window.scrollTo(0,0);
    }

    // MULTI PAGE LAYOUT -- HomePage
    // Want a carosel at the top of gardens with the first grid wording layered on top
    // Some testimonials

    render() {
        const {classes} = this.props;

        return (
            <div className={classes.root}>
                <Grid container alignContent='center' alignItems='center' className={classes.splashImage}>
                    <Grid item xs={12}>
                        <Typography variant='h2' align='center' color='inherit'>Ladybug Landscape Design</Typography>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <div className={classes.content}>
                            <Typography variant={(window.innerWidth < 600 ) ? 'h5' : 'h3'} align='center' color='primary' className={classes.pushDown}>Bring Your Garden To Life</Typography>
                            <Divider variant='middle' />
                            <PictureGrid sourcePictures={servicePictures} showTitle showDescription />
                        </div>
                    </Grid>
                    <Grid item xs={12} className={classes.colorSection}>
                        <Typography align='center' variant='h6' color='inherit'>Contact Us Today</Typography>
                        <Typography align='center' color='inherit'>
                            Hours of Operation <br />
                            Monday-Friday 8AM to 5PM <br />
                            214-693-1500
                        </Typography>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default withStyles(styles)(HomePage);