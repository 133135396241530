import React, {useEffect} from 'react';
import { withStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import GalleryThumbnail from '../../component/GalleryThumbnail/GalleryThumbnail';
import picImporter from '../../assets/picImporter';

const styles = theme => ({
    root: {
        margin: `${theme.spacing(2)}px 0px`
    },
    content: {
        [theme.breakpoints.up('sm')]: {
            margin: "20px auto",
            width: "50%",
        },
        [theme.breakpoints.down('sm')]: {
            margin: `${theme.spacing(2)}px `,
        },
    }
})

const Gallery = (props) => {
    useEffect(() => {
        window.scrollTo(0,0);
    })
    const theme = useTheme();
    const SMALL_SCREEN = useMediaQuery(theme.breakpoints.down('sm'));

    const {classes} = props;
    const picImportPathObj = picImporter(require.context('../../assets/pictures/layoutPhotos', false, /\.jpg$|\.jpeg$/i));

    const thumbnail = {
        2017: {
            title: '2017 Gallery',
            imgSrc: picImportPathObj['0849_2017Gallery.JPG'],
            route: '2017Gallery'
        },
        2018: {
            title: '2018 Gallery',
            imgSrc: picImportPathObj["1724_2018Gallery.JPG"],
            route: '2018Gallery'
        },
        2019: {
            title: '2019 Gallery',
            imgSrc: picImportPathObj["2639_2019Gallery.JPG"],
            route: '2019Gallery'
        },
        2020: {
            title: '2020 Gallery',
            imgSrc: picImportPathObj["1756_2020Gallery.JPEG"],
            route: '2020Gallery'
        }
    }

    return (
        <div className={classes.root}>
            <Typography variant={SMALL_SCREEN ? 'h4' : 'h2'} align='center' color='primary'>Gallery of Gardens</Typography>
            <div className={classes.content}>
                <GalleryThumbnail gallery={thumbnail[2020]} />
                <GalleryThumbnail gallery={thumbnail[2019]} />
                <GalleryThumbnail gallery={thumbnail[2018]} />
                <GalleryThumbnail gallery={thumbnail[2017]} />
            </div>
        </div>
    );
}

export default withStyles(styles)(Gallery);