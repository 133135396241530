import React, { useReducer, useState, useEffect} from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import * as formLogic from '../../assets/formLogic/contactFormLogic';


const useStyles = makeStyles(theme => ({
    root: {
        margin: '0px auto',
        width: '50%'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
    },
    inputSpace: {
        [theme.breakpoints.up('sm')]:{
            margin: `0px ${theme.spacing(2)}px`
        }
    },
    phoneWidth: {
        [theme.breakpoints.down('sm')]: {
            width: `85%`,
            margin: `0px auto`
        }    
    }

}));
const {formReducer, formState, formSubmitter, formTyper, formValidator} = formLogic

const ContactForm = () => {
    const classes = useStyles();
    const theme = useTheme();
    const [state, dispatch] = useReducer(formReducer, formState);
    const [loading, setLoading] = useState(false);
    const [snackShow, setSnackShow] = useState(false);
    const SMALL_SCREEN = useMediaQuery(theme.breakpoints.down('sm'));
    useEffect(() => {
        if (state.validForm === true){
            formSubmitter(state, dispatch, setLoading, setSnackShow);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.validForm, state.showError])
    
    const closeSnack = () => setSnackShow(false);
    const errorTexts = {
        phone: "Format: ###-###-####",
        email: "Format: sample@example.com"
    }
    const {firstName, lastName, email, phone, message, showError } = state;

    return (
        <>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color='primary' size="5rem" />
            </Backdrop>
            <form onSubmit={(event) => formValidator(event, dispatch)} disabled={loading} id='contactForm'>
                <Grid container spacing={1} item className={classes.phoneWidth}>
                    <Grid item xs={SMALL_SCREEN ? 12 : null}>
                        <TextField
                            id='firstName'
                            label='First Name'
                            name='firstName'
                            margin='dense'
                            variant='filled'
                            value={firstName.value}
                            error={!firstName.valid && showError}
                            required
                            fullWidth={SMALL_SCREEN ? true : false}
                            onChange={(event) => formTyper(event, dispatch)} />
                    </Grid>
                    <Grid item xs={SMALL_SCREEN ? 12 : null}>
                        <TextField
                            id='lastName'
                            label='Last Name'
                            name='lastName'
                            margin='dense'
                            variant='filled'
                            fullWidth={SMALL_SCREEN ? true : false}
                            value={lastName.value}
                            error={!lastName.valid && showError} required onChange={(event) => formTyper(event, dispatch)} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id='email'
                            label='Email'
                            type='email'
                            margin='dense'
                            fullWidth
                            variant='filled'
                            autoComplete='email'
                            placeholder='yourmail@emails.com'
                            helperText={(!email.valid && showError) ? errorTexts.email : ''}
                            name='email'
                            value={email.value}
                            error={!email.valid && showError}
                            required onChange={(event) => formTyper(event, dispatch)} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id='phone'
                            label='Phone'
                            type='tel'
                            margin='dense'
                            fullWidth
                            variant='filled'
                            autoComplete='tel-national'
                            placeholder='215-867-5309'
                            helperText={(!phone.valid && showError) ? errorTexts.phone : ''}
                            name='phone'
                            value={phone.value}
                            error={!phone.valid && showError}
                            onChange={(event) => formTyper(event, dispatch)} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id='message'
                            label='message'
                            margin='dense'
                            variant='filled'
                            fullWidth
                            multiline
                            rows={10}
                            name='message'
                            placeholder='How can we help you today?'
                            value={message.value} error={!message.valid && showError} required onChange={(event) => formTyper(event, dispatch)} />
                    </Grid>
                </Grid>
            </form>
            <div className={classes.phoneWidth}>
            <Button type='submit' variant='outlined' form='contactForm' color='primary' fullWidth >Submit</Button>
            </div>
            <Snackbar
                open={snackShow}
                autoHideDuration={5000}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                onClose={closeSnack}
                message="Submit Success"
            />
        </>
    )
}
export default ContactForm;