import React, { useState } from 'react';
import { useTheme } from '@material-ui/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import GalleryModal from '../GalleryModal/GalleryModal';

// Template for rendering a photo grid given an array of image objects
// Add showTitle prop to show image titles
// Add showDescription prop to show image description (if applicable)


const PictureGrid = (props) => {
    // New useState Hook!
    const [open, setOpen] = useState(false),
        [clickedData, setClickedData] = useState();
    const theme = useTheme();
    const SMALL_SCREEN = useMediaQuery(theme.breakpoints.down('sm'));
    // const SMALL_SCREEN = useMediaQuery(props.theme.breakpoints.down('sm'));
    
    const { sourcePictures, showTitle, showDescription } = props;

    // Opens the dialog and caputres the metadata of photo clicked
    // Pass clicked metadata to modal to start slideshow at clicked photo
    const handleDialogOpen = (event) => {
        setClickedData(event.currentTarget.dataset.picnum)
        setOpen(true);
    } 
    const handleDialogClose = () => setOpen(false);

    // Need to make it responsive, only show one column on phones
    // Disable modal on mobile phones
    
    return (
        <div>
            <GridList cols={SMALL_SCREEN ? 1 : 3} spacing={SMALL_SCREEN ? 4 : 20}>
                {sourcePictures.map(tile => (
                    <GridListTile
                    key={tile.title} 
                    rows={SMALL_SCREEN ? (tile.rows || 1) : (tile.rows || 2)} 
                    cols={tile.cols || 1} 
                    onClick={props.galleryMode ? handleDialogOpen : null}
                    data-picnum={tile.title}>
                        <img src={tile.imgSrc} alt={tile.title} />
                        {showTitle
                            ?
                            <GridListTileBar
                                title={tile.title}
                                subtitle={showDescription ? tile.description : '' } /> 
                            :
                                ''}
                    </GridListTile>
                ))}
            </GridList>
            <GalleryModal 
            isOpen={open} 
            handleClose={handleDialogClose} 
            clickedData={clickedData} 
            sourcePictures={sourcePictures} />
        </div>
    );
}

export default PictureGrid;