import React, { Component, Fragment } from 'react';
import TopNavbar from '../../component/Navigation/TopNavbar';
import SideNavBar from '../../component/Navigation/SideNavBar';
import BottomNavBar from '../../component/Navigation/BottomNavBar';

// Import a toolbar here at

class Layout extends Component{
    state = {
        open: false
    }

    toggleDrawer = () => (
        this.setState(prevState => ({open: !prevState.open}))
    )

    render() {
        return (
            <Fragment>
                <TopNavbar toggleDrawer={this.toggleDrawer} />
                <SideNavBar open={this.state.open} toggleDrawer={this.toggleDrawer} />
                <main>
                    {this.props.children}
                </main>
                <BottomNavBar />
            </Fragment>
        );
    }
} 

export default Layout;