import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import { compose } from 'redux';
import Link from '@material-ui/core/Link';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import withWidth from '@material-ui/core/withWidth';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import logo from '../../assets/logo/ladybug_logo.png';

const styles = theme => ({
    root: {
        // paddingBottom: 85,
        [theme.breakpoints.up('sm')]: {
            // paddingBottom: `${theme.spacing(2)}px `
        }
    },
    grow: {
        flexGrow: 1,
    },
    link: {
        padding: '0 13px 0 13px',
    },
    logo: {
        margin: 0,
        width: '18vh',
        [theme.breakpoints.up('sm')]:{
            width: 120,
        }
    }
})

const myActiveStyle = {
    fontWeight: "bold",
}

const topNavbar = (props) => {
    const { classes } = props;

    // Will Show the MenuIcon on Phones and a full Navbar otherwise
    return (
        <header className={classes.root}>
            <AppBar position='static'>
                <Toolbar >
                    <Hidden mdUp>
                        <IconButton
                            color='inherit' 
                            onClick={props.toggleDrawer}
                            aria-label='Open Left Menu'
                        >
                            <MenuIcon />
                        </IconButton>
                    </Hidden>
                    <IconButton>
                        <Link
                            component={NavLink}
                            to='/'>
                            <img src={logo} to='/' alt='Logo' className={classes.logo} />
                        </Link>
                    </IconButton>
                    <Hidden smDown>
                    <div className={classes.grow}>
                        <Link
                            component={NavLink}
                            exact
                            color='inherit'
                            to='/'
                            activeStyle={myActiveStyle}
                            className={classes.link}
                        >
                            Home
                        </Link>
                        <Link component={NavLink} to='/services' color='inherit' activeStyle={myActiveStyle} className={classes.link}>Services</Link>
                        <Link component={NavLink} to='/gallery' color='inherit' activeStyle={myActiveStyle} className={classes.link}>Gallery</Link>
                        <Link component={NavLink} to='/about' color='inherit' activeStyle={myActiveStyle} className={classes.link}>About</Link>
                        <Link component={NavLink} to='/contact' color='inherit' activeStyle={myActiveStyle} className={classes.link}>Contact</Link>
                    </div>
                    </Hidden>
                    {/* <Typography variant="subtitle1" color='inherit'>Current width: {props.width}</Typography> */}
                </Toolbar>
            </AppBar>
        </header>
    )
}

export default compose(withStyles(styles),withWidth())(topNavbar);