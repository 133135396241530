
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Slide from './Slide';
import IconButton from '@material-ui/core/IconButton';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';

// Slideshow houses all of the other components. Will contain logic for slideshow
const styles = theme => ({
    root: {
        width: '100vh',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        margin: 'auto auto',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    slideWrapper: {
        height: '100%',
        width: '100%',
        position: 'relative'
    },
    rightIcon: {
        position: 'absolute',
        [theme.breakpoints.up('md')]: {
            top: '40%',
            backgroundColor: theme.palette.background.paper,
        },
        zIndex: 999,
        right: '.5%',
    },
    leftIcon: {
        position: 'absolute',
        [theme.breakpoints.up('md')]: {
            top: '40%',
            backgroundColor: theme.palette.background.paper,
        },
        zIndex: 999,
        left: '.5%',
    },
    largeIcon: {
        width: 50,
        height: 50,
    }
})

class Slideshow extends Component {
    state = {}

    componentDidMount(){
        this.setState({slideImages:this.makeImageObject(this.props.sourcePictures)})
    }

    // Take all the photos and make them into an object with an 'in' state and 'direction'
    makeImageObject = sourceArray => (
        sourceArray.map((image, index) => (
            {
                imgSrc: image.imgSrc,
                title: image.title,
                // Set whatever index was clicked on to true
                in: (image.title === this.props.startSlide ? true : false),
                direction: (index === 0 ? 'right' : 'left'),
                index: index
            }
        ))
    )

    // Loop through the state array and change the 'in' property to false to trigger the leave animation
    goToNextSlide = () => {
        let updatedState = [...this.state.slideImages],
            updatedFirstObj,
            updatedSecondObj;

        for (let i = 0; i < updatedState.length; i++) {
            if (updatedState[i].in === true) {
                updatedFirstObj = {
                    ...updatedState[i],
                    in: false,
                    direction: 'right'
                }
                // If the next index isn't the end, change 'in' to true to trigger enter animation
                if (((i + 1) < updatedState.length)) {
                    updatedSecondObj = {
                        ...updatedState[i + 1],
                        in: true,
                        direction: 'left'
                    }
                    updatedState[i + 1] = updatedSecondObj;
                    // If it is the end, change the first index to true to start over
                } else {
                    updatedSecondObj = {
                        ...updatedState[0],
                        in: true,
                        direction: 'left'
                    }
                    // First value removes the last array. Second brings in the first 
                    updatedState[i] = updatedFirstObj;
                    updatedState[0] = updatedSecondObj;
                    this.setState({ slideImages: updatedState })
                    break;
                }
                updatedState[i] = updatedFirstObj;
                this.setState({ slideImages: updatedState })
                // Break prevents unecessary loop runs
                break;
            }
        }
    }

    goToPrevSlide = () => {
        let updatedState = [...this.state.slideImages],
        updatedFirstObj,
        updatedSecondObj;

    for (let i = updatedState.length -1; i >= 0; i--) {
        if (updatedState[i].in === true) {
            updatedFirstObj = {
                ...updatedState[i],
                in: false,
                direction: 'left'
            }
            // If the next index isn't the end, change 'in' to true to trigger enter animation
            if (((i - 1) >= 0)) {
                updatedSecondObj = {
                    ...updatedState[i - 1],
                    in: true,
                    direction: 'right'
                }
                updatedState[i - 1] = updatedSecondObj;
                // If it is the end, change the first index to true to start over
            } else {
                updatedSecondObj = {
                    ...updatedState[updatedState.length - 1],
                    in: true,
                    direction: 'right'
                }
                // First value removes the last array. Second brings in the first 
                updatedState[i] = updatedFirstObj;
                updatedState[updatedState.length - 1] = updatedSecondObj;
                this.setState({ slideImages: updatedState })
                break;
            }
            updatedState[i] = updatedFirstObj;
            this.setState({ slideImages: updatedState })
            // Break prevents unecessary loop runs
            break;
        }
    }
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <div className={classes.slideWrapper}>
                    {
                        this.state.slideImages 
                        ?
                        this.state.slideImages.map((imageObj, index)=>(
                            <Slide key={index} imageobject={imageObj} />
                        ))
                        :
                        <div></div>
                    }
                </div>
                <IconButton color='secondary' onClick={this.goToPrevSlide} className={classes.leftIcon}><ArrowBack className={classes.largeIcon} /></IconButton>
                <IconButton color='secondary' onClick={this.goToNextSlide} className={classes.rightIcon}><ArrowForward className={classes.largeIcon} /></IconButton>
            </div>
        );
    }
}

export default withStyles(styles)(Slideshow)