import React, {useEffect} from 'react';
import {withStyles, useTheme} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ServiceCards from '../../component/ServiceCards/ServiceCards';


const styles = theme =>({
    root: {
        margin: `${theme.spacing(2)}px 0px`
    }
})

const Services = (props) => {
    useEffect(() => {
        window.scrollTo(0,0);
    })
    const theme = useTheme();
    const SMALL_SCREEN = useMediaQuery(theme.breakpoints.down('sm'));

    const {classes} = props;

    return(
        <div className={classes.root}>
           <Typography variant={SMALL_SCREEN ? 'h4' : 'h2'} align='center' color='primary'>Services Offered</Typography>
            <ServiceCards />
        </div>
    );
}

export default withStyles(styles)(Services);