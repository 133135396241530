import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import { NavLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider';


const useStyles = makeStyles(theme => ({
    root: {
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
        backgroundColor: '#455a64',
    },
    fontColor: {
        color: '#eceff1'
    }
}))

const BottomNavBar = (props) => {
    const classes = useStyles();
    const navigationItems = ['/','services','gallery','about','contact'];
    const navigationList = navigationItems.map((name, index) => (
        <ListItem key={index} dense>
            <Link component={NavLink} exact={name === '/' ? true : false} to={name === '/' ? '/' : `/${name}`} className={classes.fontColor} >
                <ListItemText primary={name === '/' ? 'Home' : name} primaryTypographyProps={{ color: 'inherit' }} />
            </Link>
        </ListItem>
    ))
    
    return (
        <footer className={`${classes.root} ${classes.fontColor}`}>
            <Grid container alignContent='center'>
                <Grid item xs={6} md={6}>
                    <List subheader={<ListSubheader color='inherit'>Navigation</ListSubheader>}dense>
                        {navigationList}
                    </List>
                </Grid>
                <Grid item xs={6} md={6}>
                    <Typography variant='body2' component='div' align='center' color='inherit' paragraph>
                        {'Contact \n Hours of Operation \n Mon-Fri 8AM to 5PM \n Phone: 214-693-1500'.split("\n").map((line, index) => {
                            return <p key={index} style={{ margin: 0 }}>{line}</p>
                        })}
                    </Typography>
                </Grid>
                <Grid item>
                    <Divider />
                    <Typography variant='subtitle2'>Powered by Myles Adams</Typography>
                </Grid>
            </Grid>
        </footer>
    )
}

export default BottomNavBar;