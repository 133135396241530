import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { createMuiTheme } from '@material-ui/core/styles';
import {ThemeProvider} from '@material-ui/styles';
import Layout from './hoc/Layout/Layout';
import HomePage from './containers/HomePage/HomePage';
import Services from './containers/Services/Services';
import Gallery from './containers/Gallery/Gallery';
import About from './containers/About/About';
import Contact from './containers/Contact/Contact';
import DetailedGallery from './containers/Gallery/DetailedGallery';

const theme = createMuiTheme({
    typography: {
        useNextVariants: true
    },
    palette:{
        primary: {
            main: '#004d40'
            // main: '#558b2f'
        },
        secondary: {
            main: '#4caf50'
            // main: '#004d40'
        },
    },
});

function App() {
    return (
        <ThemeProvider theme={theme}>
            <Layout>
                <Switch>
                    <Route path='/' exact component={HomePage} />
                    <Route path='/services' component={Services} />
                    <Route path='/gallery' exact component={Gallery} />
                    <Route path='/gallery/:detailView' component={DetailedGallery} />
                    <Route path='/about' component={About} />
                    <Route path='/contact' component={Contact} />
                </Switch>
            </Layout>
        </ThemeProvider>
    );
}

export default App;