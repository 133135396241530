import picImporter from './picImporter';
/* Local solution to creating an image gallery.
Feed a year representing directory to grab pictures from */
const galleryGenerator = yearDic => {

    let picImportPathObj = {}
    // Can't use a dynamic variable in a require call
    switch (yearDic) {
        case 2016-2017:
            picImportPathObj = picImporter(require.context('./pictures/2016-2017', false, /\.jpg$/i));
            break;
        case 2018:
            picImportPathObj = picImporter(require.context('./pictures/2018', false, /\.jpg$/i));        
            break;
        case 2019:
            picImportPathObj = picImporter(require.context('./pictures/2019', false, /\.jpg$/i));
            break;
        case 2020:
            picImportPathObj = picImporter(require.context('./pictures/2020', false, /\.jpg$/i));
            break;
        default:
            break;
    }

    // Create metadata for each picture
    const galleryPictures = []
    Object.keys(picImportPathObj).map((picture, index) => {
        return (galleryPictures.push({
            title: picture.toLowerCase().slice(0, -4),
            imgSrc: picImportPathObj[picture],
            cols: index % 2,
            rows: (index % 2) + 1 
        }))
    })
    return galleryPictures;
}
export default galleryGenerator;