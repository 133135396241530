import React, {useEffect} from 'react';
import { withStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

const styles = theme => ({
    root:{
        margin: `${theme.spacing(2)}px  auto`,
        [theme.breakpoints.up('sm')]: {
            width: "50%",
        },
    },
    paper: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    }
})

const About = (props) => {
    useEffect(() => {
        window.scrollTo(0,0);
    })
    const theme = useTheme();
    const SMALL_SCREEN = useMediaQuery(theme.breakpoints.down('sm'));

    const { classes } = props;

    return(
        <div className={classes.root}>
            <Typography variant={SMALL_SCREEN ? 'h3' : 'h2'} align='center' color='primary' gutterBottom>About Us</Typography>
            <Paper className={classes.paper}>
                <Typography paragraph>
                    LadyBug Landscape specializes in home garden design and maintenance in the greater Dallas area.
                    Bonnie Adams started LadyBug Landscape back in 2008. After a lifelong love of gardens, she
                    decided to go back to school and start her own business doing what she loved.
            </Typography>

                <Typography paragraph>
                    LadyBug Landscape is proud to have designed and maintained over 150 properties since its founding,
                    providing beautifully botanic landscapes to scores of happy homes. Working closely with home owners,
                    we design and implement gardens year-round. Our focus on maintenance will ensure your garden
                    will dazzle your neighbors’ season after season.
            </Typography>

                <Typography>We look forward to working with you to turn your home garden into a bonny landscape!</Typography>
            </Paper>
        </div>
    );
}

export default withStyles(styles)(About);

